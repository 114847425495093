
.detailed-status > .media-spoiler,
.status > .media-spoiler,
.status .video-player,
.media-gallery,
.status .status-card.interactive {
  margin-top: 20px;
  margin-left: -68px;
  width: calc(100% + 80px);
}

.detailed-status > .media-spoiler,
.status > .media-spoiler,
.video-player {
  max-width: none;
}

/* If there's no status text, add an extra margin on top */
.status .status__info + .media-gallery,
.status .status__info + .media-spoiler,
.status .status__info + .video-player,
.status .status__info + .status-card {
  margin-top: 40px;
}

.status__video-player-video {
  transform: unset;
  top: unset;
}

.detailed-status .media-gallery {
  margin-left: -10px;
  width: calc(100% + 22px);
}

.public-layout .status {
  .status__content {
    min-height: 15px;
  }
  & > .media-spoiler,
  .video-player,
  .media-gallery,
  .status-card {
    margin-top: 20px;
    width: calc(100% + 94px);
    margin-left: -78px;
  }
}

.quote-status .media-gallery {
  margin-left: 0;
  width: 100%;
}